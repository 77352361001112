import {LitElement, html} from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement('sbk-target-groups-element')
export class TargetGroupsElement extends LitElement {

  @property({type: String, attribute: 'headline'})
  headline = ''

  image?: HTMLImageElement | null;

  @property({type: Number, attribute: 'image-facet'})
  imageFacet?: 1 | 2 | 4 | 5 | 6 = 1;

  @property({type: String, attribute: 'content'})
  content = ''

  @property({type: String, attribute: 'button-label'})
  buttonLabel = ''

  @property({type: String, attribute: 'link'})
  link = ''

  firstUpdated() {
    this.image = this.querySelector('img');
  }

  render() {
    return html`
    `;
  }
}